import React from "react";
import styled, { css } from "styled-components";
import theme from "../../assets/theme";

export default function FilterBar({
  filters = [],
  selectedFilter = "All",
  setSelectedFilter = () => {},
}) {
  return (
    <FilterContainer>
      {filters.map((filter, index) => (
        <FilterTab
          key={index}
          onClick={() => {
            if (filter !== selectedFilter) {
              setSelectedFilter(filter.label);
            }
          }}
          selected={filter.label === selectedFilter}
        >
          {filter.label} {filter.value >= 0 && `(${filter.value})`}
        </FilterTab>
      ))}
    </FilterContainer>
  );
}

const FilterContainer = styled.div`
  height: 31px;
  border-radius: 15.5px;
  background-color: #f4f6ff;
  border: solid 1px #e1e3ee;
  display: flex;
  align-items: center;
  padding: 0 5px;
`;

const FilterTab = styled.div`
  height: 23px;
  border-radius: 11.5px;
  padding: 0 10px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: #acb1c2;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  ${({ selected }) =>
    selected &&
    css`
      color: white;
      background-color: #00838c;
    `};
`;
