import React from "react";
import {
  AWAITING_RESPONDANT,
  RESPONDANT_ONBOARDED,
  NEGOTIATION_ONGOING,
  NEGOTIATION_REACHED,
  NEGOTIATION_QUIT_BY_CLAIMENT,
  NEGOTIATION_QUIT_BY_RESPONDENT,
  CLAIMENT_PAID_NEGOTIATION,
  BOTH_PAID_NEGOTIATION,
  RESPONDENT_PAID_NEGOTIATION,
  NEGOTIATION_QUIT_BY_CASEMANAGER,
  AWAITING_RESPONDANT_QUIT,
  awaitingRespondentAcceptance,
} from "../../../helpers/constants";
import CommentBox from "./common/CommentBox";
import CaseAgreement from "./common/CaseAgreement";
import AgreementDocCard from "./common/AgreementDocCard";
import _ from "lodash";

function MediationState({
  currentState,
  isRespondent,
  ownerId,
  caseDetails,
  showCta,
  comments,
  setComments,
  partyList = [],
}) {
  /**
   * @description This function is to decide whether the respondent view is shown are not
   * @param rounds
   */

  const RenderCommentBox = ({
    status,
    expand,
    errorText,
    comments,
    caseId,
    setComments,
    disabled,
  }) => {
    return (
      <CommentBox
        title={`${_.capitalize(caseDetails?.resolutionKind)} Process`}
        expand={expand}
        status={status}
        errorText={errorText}
        ownerId={ownerId}
        caseId={caseId}
        comments={comments}
        disabled={disabled}
        setComments={setComments}
        partyList={partyList}
        kind={
          caseDetails?.resolutionKind === "mediation"
            ? "Mediator"
            : "Arbitrator"
        }
      />
    );
  };

  const AgreementSection = () => {
    return (
      <>
        <div style={{ marginTop: 28 }}>
          <CaseAgreement
            title={`Final Award Summary for Case ID: ${caseDetails?.id}`}
            message={caseDetails?.negotiationSummary}
            summaryStatus={caseDetails?.summaryStatus === "completed"}
            isPaid={currentState?.cta?.length === 0}
          />
        </div>
        <div style={{ marginTop: 28 }}>
          <AgreementDocCard
            id={caseDetails?.id}
            status={caseDetails.status}
            isRespondent={isRespondent}
            hasRating={caseDetails?.rating}
            hasAgreement={caseDetails?.hasAgreementDocument}
            type={`Download`}
          />
        </div>
      </>
    );
  };

  switch (currentState?.key) {
    case AWAITING_RESPONDANT:
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            expand={true}
            caseId={caseDetails?.id}
            errorText={"Respondent Declined the Case"}
            status={caseDetails.respondentStatus === "declined" && "error"}
            comments={comments}
            setComments={setComments}
            disabled={true}
          />
        </div>
      );
    case awaitingRespondentAcceptance:
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            expand={true}
            caseId={caseDetails?.id}
            errorText={"Respondent Declined the Case"}
            status={caseDetails.respondentStatus === "declined" && "error"}
            comments={comments}
            setComments={setComments}
            disabled={true}
          />
        </div>
      );
    case AWAITING_RESPONDANT_QUIT: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Closed by Case Manager"}
            comments={comments}
            setComments={setComments}
            disabled={true}
          />
        </div>
      );
    case RESPONDANT_ONBOARDED:
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            expand={true}
            caseId={caseDetails?.id}
            errorText={"Respondent Declined the Case"}
            status={caseDetails.respondentStatus === "declined" && "error"}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case NEGOTIATION_ONGOING: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case NEGOTIATION_QUIT_BY_CLAIMENT: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Quit by Claimant"}
            comments={comments}
            setComments={setComments}
            disabled={true}
          />
        </div>
      );
    case NEGOTIATION_QUIT_BY_RESPONDENT: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Quit by Respondent"}
            comments={comments}
            setComments={setComments}
            disabled={true}
          />
        </div>
      );
    case NEGOTIATION_QUIT_BY_CASEMANAGER: // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            status={"error"}
            errorText={"Case Closed by Case Manager"}
            comments={comments}
            setComments={setComments}
            disabled={true}
          />
        </div>
      );
    case NEGOTIATION_REACHED:
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
            disabled={true}
          />
        </div>
      );
    case CLAIMENT_PAID_NEGOTIATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              disabled={true}
            />
          </div>
          <AgreementSection />
        </>
      );
    case RESPONDENT_PAID_NEGOTIATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              caseId={caseDetails?.id}
              expand={false}
              comments={comments}
              setComments={setComments}
              disabled={true}
            />
          </div>
          <AgreementSection />
        </>
      );
    case BOTH_PAID_NEGOTIATION:
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              disabled={true}
            />
          </div>
          <AgreementSection />
        </>
      );

    // new stages

    case "awaitingRespondentAcceptance": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "noticeToArbitrate": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "arbitrationReferenced": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "arbitrationNominated": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "arbitrationConfirmed": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "waitingForArbitratorConfirmation": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "firstNoticeSent": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "statementOfClaimFiled": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "awaitingForSec17Details": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "sec17PetitionFiled": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "sec17Ordered": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "awaitingSec17Execution": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "sec17ExtensionMemoFiled": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "sec17ExtensionMemoOrdered": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "fsOrdered": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "attachmentOrdered": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "garnisheeProhibitaryOrder": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "garnisheeFinalOrder": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "sec17Closed": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "statementOfDefenceFiled": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "rejoinderFromClaimant": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "surrejoinderFromRespondent": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "2ndNoticeMOM": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "evidenceFiledByClaimant": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "evidenceFiledByRespondent": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "crossExamination": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "argumentsByClaimant": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "argumentsByRespondent": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "negotiationOngoing": // Currently Working On this flow
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "firstHearing":
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "secondHearing":
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "thirdHearing":
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "firstMom":
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "commencementLetter":
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "arbitrationInprogress":
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "referenceLetter":
      return (
        <div style={{ marginTop: 28 }}>
          <RenderCommentBox
            caseId={caseDetails?.id}
            expand={true}
            comments={comments}
            setComments={setComments}
          />
        </div>
      );
    case "reservedForAward": // Currently Working On this flow
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
            />
          </div>
        </>
      );
    case "awardPassed": // Currently Working On this flow
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
            />
          </div>
        </>
      );
    case "resolutionFailed": // Currently Working On this flow
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
            />
          </div>
        </>
      );
    case "settledAndWithdrawn": // Currently Working On this flow
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
            />
          </div>
        </>
      );
    case "updatedAndWithdrawn": // Currently Working On this flow
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
            />
          </div>
        </>
      );
    case "finalAward": // Currently Working On this flow
      return (
        <>
          <div style={{ marginTop: 28 }}>
            <RenderCommentBox
              expand={false}
              caseId={caseDetails?.id}
              comments={comments}
              setComments={setComments}
              disabled={true}
            />
          </div>
          <AgreementSection />
        </>
      );
    default:
      return null;
  }
}

export default React.memo(MediationState);
