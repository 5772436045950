import React, { useEffect, useState, memo } from "react";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ActionBar from "../../../common/ActionBar";
import { navigate } from "@reach/router";
import _ from "lodash";
import { PrimaryCTAButton, SecondaryCTAButton } from "../../../common/Buttons";
import { CustomInputField } from "../../../common/FormInputs";
import { Formik } from "formik";
import SystemService from "../../../../services/SystemService";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import {
  OuterContainer,
  Container,
  FormContainer,
  TableContainer,
  TableWrapper,
  BigTable,
  DeleteIcon,
  ButtonWrapper,
  Row,
  FormControl,
} from "./CaseStageState";

const useStyles = makeStyles({
  table: {
    backgroundColor: "transparent",
    borderRadius: 7,
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
  tableHead: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.INPUT_LABEL,
    fontSize: 10,
    paddingBottom: 0,
  },
  row: {
    borderBottom: `1px solid ${COLORS.INPUT_BORDER}`,
  },
  radioGroup: {
    "& .MuiRadio-colorPrimary.Mui-checked": {
      color: COLORS.BTN_GREEN,
    },
  },
  formLabel: {
    fontFamily: theme.fonts.primaryFontRegular,
    color: COLORS.COLOR_DARK,
    marginRight: 5,
    // minWidth: 98,
  },
  radioButton: {
    color: COLORS.BTN_GREEN,
  },
});

const DocumentTypeState = () => {
  const classes = useStyles();
  const [documetItems, setDocumentItems] = useState([]);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [trigger, setTrigger] = useState(true);
  const initialState = {
    documentCategory: "",
    documentType: "",
  };

  useEffect(() => {
    async function getDocumentTypes() {
      try {
        setLoader({ state: true, message: "Fetching details..." });
        const response = await SystemService.getDocumentTypes();
        if (response) {
          setDocumentItems(response?.documentTypes);
        }
      } catch (err) {
        throw err;
      } finally {
        setTrigger(false);
        setLoader({ state: false });
        window.scrollTo(0, 0);
      }
    }
    if (trigger) {
      getDocumentTypes();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "configurations":
        navigate("/dashboard/configurations");
        break;
      default:
        break;
    }
  };

  const addDocumentItems = (values, setFieldValue) => {
    const new_document_items = [
      ...values?.document_items,
      {
        documentCategory: "",
        documentType: "",
        addItem: true,
      },
    ];
    setFieldValue("document_items", new_document_items);
  };

  const deleteDocumentTypes = async (index, values, setFieldValue, id) => {
    try {
      setLoader({ state: true, message: "delete item..." });
      const new_document_items = [...values?.document_items].filter(
        (ci, idx) => idx !== index,
      );
      const response = await SystemService.deleteDocumentTypes(id);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      setFieldValue("document_items", new_document_items);
    } catch (err) {
      enqueueSnackbar(err?.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const InputField = memo(({ key, name, onChange, onBlur, value }) => {
    return (
      <div>
        <CustomInputField
          className={"input-white"}
          variant="outlined"
          key={key}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
    );
  });

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "update case stages..." });
      let filteredItems = values?.document_items
        .filter((arr) => arr?.addItem)
        .map(({ addItem, ...item }) => item);
      const payload = {
        documentTypeParams: filteredItems,
      };
      const response = await SystemService.documentTypes(payload);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setTrigger(true);
    }
  }

  return (
    <OuterContainer>
      <ActionBar
        breadcrumbs={["Configurations", "Document Types"]}
        {...{ onBreadcrumbClick }}
      />
      <Container>
        <Formik
          initialValues={{
            document_items: documetItems?.length
              ? documetItems?.map((item) => ({
                  ...item,
                  documentCategory: item?.documentCategory,
                  documentType: item?.documentType, // Comment for always its going to INR
                  id: item?.id,
                }))
              : [initialState] || [initialState],
          }}
          onSubmit={onFormSubmit}
          enableReinitialize
          validateOnBlur
          validateOnChange
          validateOnMount
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            setFieldError,
            setFieldValue,
            handleBlur,
            isValid,
            setFieldTouched,
          }) => (
            <FormContainer className="material-table" onSubmit={handleSubmit}>
              <TableContainer>
                <TableWrapper>
                  <BigTable>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              width: 253,
                            }}
                            className={classes.tableHead}
                          >
                            Document Category
                          </TableCell>
                          <TableCell
                            style={{
                              width: 253,
                            }}
                            className={classes.tableHead}
                          >
                            Document Type
                          </TableCell>
                          <TableCell className={classes.tableHead}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values?.document_items?.map((arr, index) => (
                          <TableRow className={"table-row"} key={index}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ position: "relative" }}
                            >
                              <InputField
                                key={index}
                                className={"input-white"}
                                value={arr.documentCategory}
                                name={`document_items[${index}].documentCategory`}
                                onChange={handleChange}
                                variant="outlined"
                                onBlur={handleBlur}
                              />
                            </TableCell>
                            <TableCell
                              className="disabledArrows"
                              style={{ position: "relative" }}
                            >
                              <InputField
                                key={index}
                                className={"input-white"}
                                value={arr.documentType}
                                name={`document_items[${index}].documentType`}
                                onChange={handleChange}
                                variant="outlined"
                                onBlur={handleBlur}
                              />
                            </TableCell>
                            <TableCell align="center" style={{ width: 100 }}>
                              {values.document_items.length > 1 && (
                                <DeleteIcon
                                  onClick={() =>
                                    deleteDocumentTypes(
                                      index,
                                      values,
                                      setFieldValue,
                                      arr.id,
                                    )
                                  }
                                  src={require("../../../../assets/images/removeIcon.svg")}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </BigTable>
                  <ButtonWrapper>
                    <SecondaryCTAButton
                      onClick={() => addDocumentItems(values, setFieldValue)}
                      style={{ paddingLeft: 0 }}
                    >
                      + Add type
                    </SecondaryCTAButton>
                  </ButtonWrapper>
                </TableWrapper>
              </TableContainer>
              <FormControl>
                <Row>
                  <PrimaryCTAButton
                    onClick={handleSubmit}
                    style={{ width: "47%", height: 35 }}
                  >
                    Submit
                  </PrimaryCTAButton>
                </Row>
              </FormControl>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default DocumentTypeState;
