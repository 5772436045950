import React, { Fragment, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { PrimaryCTAButton } from "../Buttons";
import CaseService from "../../../services/CaseService";
import CaseManagerService from "../../../services/CaseManagerService";
import CustomTable from "../../CustomTable/MaterailCustomTable";
import { navigate } from "@reach/router";
import {
  getCaseLabelByStatus,
  numberFormat,
  parseTimeStamp,
  getErrorMessage,
} from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";
import moment from "moment";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import AlertDialog from "../Alert";
import { CustomCheckbox } from "../FormInputs";
import PartyService from "../../../services/PartyService";
import { StyledSelectFormControl } from "../CustomSelect/styles";
import useRefContext from "../../../hooks/useRefContext";
import MySelect from "./MultiSelect";

const initFilters = [
  { label: "All", key: "allCount", value: 0 },
  {
    label: "Waiting For Respondent",
    key: "awaitingRespondentOnBoardingCount",
    value: 0,
  },
  { label: "Under Resolution", key: "underResolution", value: 0 },
  { label: "Settled", key: "settled", value: 0 },
  { label: "Drafts", key: "draftCount", value: 0 },
];

const filterByStatus = (selectedFilter) => {
  let key = "status";
  let value = "";
  switch (selectedFilter) {
    case "Drafts":
      value = "draft";
      break;
    case "Under Resolution":
      value = "underResolution";
      break;
    case "Waiting For Respondent":
      value = "awaitingRespondent";
      break;
    case "Settled":
      value = "settled";
      break;
    default:
      value = "";
      break;
  }
  return { key, value };
};

const CaseTable = ({ id, caseType, currentCaseType, role = "" }) => {
  const [state, setState] = useState([]);
  const [filters, setFilters] = useState(initFilters);
  const [selectedFilter, setSelectedFilter] = useState();
  const MTRef = useRef();
  const { setLoader } = useLoader();
  const [page, setPage] = useState(1);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [referesh, setRefresh] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  // let partyRef = useRef({
  //   partyId: [],
  // });
  const { partyRef, selectedOption, setSelectedOption } = useRefContext();

  /**
   * @description get parties
   */

  useEffect(() => {
    async function getParties(params) {
      try {
        const response = await PartyService.index(
          "?perPage=4000&status=active",
        );
        if (response?.data?.length) {
          setMenuItems(
            response.data.map(({ name, id, owner }) => ({
              label: `${name} (Party Id:${id})`,
              value: id,
              id: id,
              email: owner?.email,
            })),
          );
        }
      } catch (error) {
        throw error;
      }
    }
    getParties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @description remove filters and party's based on data
   */

  useEffect(() => {
    if (partyRef.current.partyId.length === 0) {
      setState([]);
      setFilters(initFilters);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyRef.current.partyId.length]);

  /**
   * @description Get all cases and stats
   */

  useEffect(() => {
    async function getAllCases() {
      try {
        setLoading(true);
        let stringParams = "";
        if (selectedFilter) {
          let status = filterByStatus(selectedFilter);
          stringParams = `?resolutionKind=${caseType}&status=${status?.value}&partyIds=${partyRef.current.partyId}`;
        } else {
          stringParams = `?resolutionKind=${caseType}&partyIds=${partyRef.current.partyId}`;
        }
        if (partyRef.current.partyId.length > 0) {
          const response = await CaseService.getAllCases(stringParams);
          if (response?.data) {
            setState(response?.data);
          }
        }
      } catch (err) {
        throw err;
      } finally {
        setSelectedIds([]);
        setLoading(false);
        window.scrollTo(0, 0);
      }
    }
    if (partyRef.current.partyId.length > 0 || selectedFilter || referesh) {
      getAllCases();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyRef.current.partyId, selectedFilter, caseType, referesh]);

  /**
   * @description get case status
   */

  useEffect(() => {
    async function getCaseStats() {
      try {
        const stats = await CaseManagerService.getCaseStats(
          `?resolutionKind=${caseType}&partyIds=${partyRef.current.partyId}`,
        );
        const mapped = initFilters.map((filter) => {
          let returnData = {
            label: filter.label,
            value: filter.value,
          };
          if (stats[filter.key] >= 0) {
            returnData.value = stats[filter.key];
          }
          return returnData;
        });
        setFilters(mapped);
      } catch (err) {
        throw err;
      } finally {
        setRefresh(false);
        window.scrollTo(0, 0);
      }
    }
    if (partyRef.current.partyId.length > 0 || referesh) {
      getCaseStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyRef.current.partyId, referesh, caseType]);

  /**
   * @description draft case selection
   */

  const handleSelect = (id, selectedIds, setSelectedIds) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedIds = [];

    if (selectedIndex === -1) {
      // ID is not in the array, so add it
      newSelectedIds = newSelectedIds.concat(selectedIds, id);
    } else {
      // ID is in the array, so remove it
      newSelectedIds = selectedIds.filter((item) => item !== id);
    }

    setSelectedIds(newSelectedIds);
  };

  const handleSelectAll = (isChecked, data, setSelectedIds) => {
    if (isChecked) {
      // If the checkbox is checked, select all IDs
      const allIds = data.map((item) => item.id);
      setSelectedIds(allIds);
    } else {
      // If unchecked, deselect all
      setSelectedIds([]);
    }
  };

  /**
   * @description Table columns
   */

  const columns = [
    {
      title: (
        <CustomCheckbox
          indeterminate={
            selectedIds.length > 0 && selectedIds.length < state.length
          }
          checked={selectedIds.length === state.length}
          onChange={(e) =>
            handleSelectAll(e.target.checked, state, setSelectedIds)
          }
        />
      ),
      sorting: false,
      hidden: selectedFilter === "Drafts" ? false : true,
      render: (rowData) => (
        <CustomCheckbox
          checked={selectedIds.includes(rowData.id)}
          onChange={() => handleSelect(rowData.id, selectedIds, setSelectedIds)}
        />
      ),
    },
    {
      field: "id",
      title: "Case ID",
      sorting: true,
      render: (rowData) => (
        <>
          <HyperLink
            onClick={() =>
              navigate(
                `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
              )
            }
          >
            {rowData.id}
          </HyperLink>
        </>
      ),
      tooltip: "Unique Identifier for the Case across JustAct platform",
    },
    {
      field: "title",
      title: "Case Title",
      render: (rowData) => (
        <Bold
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
            )
          }
        >
          {rowData.title}
        </Bold>
      ),
      sorting: false,
    },
    {
      field: "party",
      title: "Party",
      tooltip: "Parties representing to file the case",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
            )
          }
        >
          {rowData?.claimantParty?.name}
        </div>
      ),
      sorting: false,
    },
    {
      field: "respondentName",
      title: "Counterparty",
      sorting: false,
      tooltip: "Party countering your case",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
            )
          }
        >
          {rowData?.respondentParty?.name ? (
            rowData.respondentParty.name
          ) : rowData?.respondentName ? (
            rowData.respondentName
          ) : rowData?.respondentParties?.length ? (
            rowData?.respondentParties[0]?.name
          ) : (
            <div style={{ marginLeft: 35 }}>-</div>
          )}
        </div>
      ),
    },
    {
      field: "totalClaimValue",
      title: "Claim Value",
      tooltip: "Claim Value of the Case",
      render: (rowData) => {
        const divStyle = {
          cursor: "pointer",
        };
        const caseItems = rowData.totalClaimValue;
        if (caseItems)
          return (
            <div
              className="container"
              style={divStyle}
              onClick={() =>
                navigate(
                  `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
                )
              }
            >
              {numberFormat(
                parseFloat(caseItems).toFixed(2),
                rowData.currencyUnit,
              )}
            </div>
          );
        else
          return (
            <div
              className="container"
              style={divStyle}
              onClick={() =>
                navigate(
                  `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
                )
              }
            >
              {"Non Monetary"}
            </div>
          );
      },
      sorting: true,
    },
    {
      field: "status",
      title: "Status",
      tooltip: "Status of the Case",
      render: (rowData) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
            )
          }
        >
          {rowData?.respondentStatus === "declined" ? (
            <div className="pending-payment">Respondent Declined the Case</div>
          ) : (
            getCaseLabelByStatus(rowData)
          )}
        </div>
      ),
    },
    {
      field: "nextHearingDate",
      title: labels["table.nextHearingDate"],
      render: (rowData) => (
        <div
          style={{
            marginLeft: "20px",
            cursor: "pointer",
          }}
          onClick={() =>
            navigate(
              `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
            )
          }
        >
          {rowData?.nextHearingDate?.date
            ? rowData?.nextHearingDate?.date
            : "-"}
        </div>
      ),
      sorting: true,
    },
    {
      field: "loanAccountNo",
      title: "Contract Number",
      hidden: caseType === "arbitration" ? false : true,
      render: (rowData) =>
        rowData.loanAccountNo ? (
          <div
            onClick={() =>
              navigate(
                `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
              )
            }
          >
            {rowData.loanAccountNo}
          </div>
        ) : (
          <div style={{ marginLeft: "25px", cursor: "pointer" }}>{"-"}</div>
        ),
      sorting: false,
    },
    {
      field: "submittedOn",
      title: "Created On",
      tooltip: "Case Created Date",
      render: (rowData) => (
        <Datecolumn>
          <div
            className="date"
            onClick={() =>
              navigate(
                `/dashboard/cases/${rowData.id}?caseType=${rowData?.resolutionKind}`,
              )
            }
          >
            {rowData?.submittedOn
              ? moment(rowData.submittedOn).format("DD/MM/YYYY")
              : moment(parseTimeStamp(rowData.created_at)).format("DD/MM/YYYY")}
          </div>
          {rowData?.status === "draft" && (
            <div
              className="delete"
              onClick={() => deleteWaring(false, rowData?.id)}
            >
              {"Delete"}
            </div>
          )}
        </Datecolumn>
      ),
    },
  ];

  const deleteWaring = (isSelectAll, id) => {
    setOpen(true);
    setDialogData({
      primaryBtnText: "Proceed",
      secondaryBtnText: "Cancel",
      clickPrimaryBtn: () =>
        isSelectAll ? deleteSelectedDraft() : deleteDraft(id),
      clickSecondarybtn: () => setOpen(false),
      onDialogClose: () => setOpen(false),
      desc: `Are you sure you want to delete this draft case(s)?`,
      heading: "Delete Case",
      descriptionTextStyle: {
        margin: "0px 60px 50px",
        textAlign: "center",
        fontFamily: theme.fonts.primaryFontSemiBold,
      },
    });
  };

  /**
   * @description Function to deleted the selected case
   */

  const deleteSelectedDraft = async () => {
    try {
      setLoader({ state: true, message: "Deleting Cases..." });
      const payload = {
        selectedCaseIds: selectedIds,
      };
      const res = await CaseService.deleteSelectedCases(payload);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
      setRefresh(true);
    }
  };

  /**
   * @description Function to trigger the delete case
   * @param {*} param0
   */
  const deleteDraft = async (id) => {
    try {
      setLoader({ state: true, message: "Deleting Cases..." });
      const res = await CaseService.deleteDraftCase(id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setOpen(false);
      setState((prevData) => prevData.filter((item) => item.id !== id));
      setFilters((prevFilters) =>
        prevFilters.map((obj) => {
          if (obj.label === "Drafts") {
            // Use spread operator to copy the object and update the key
            return {
              ...obj,
              value: obj.value - 1,
            };
          }
          return obj; // Return the original object if not the one to be updated
        }),
      );
    }
  };

  /**
   * @description sorted menu items
   */

  const sortedOptions = menuItems.sort((a, b) => {
    const aSelected = selectedOption.some((option) => option.value === a.value);
    const bSelected = selectedOption.some((option) => option.value === b.value);

    if (aSelected && !bSelected) return -1;
    if (!aSelected && bSelected) return 1;
    return 0;
  });

  const handleChange = (selected) => {
    if (selected.length === 0) {
      partyRef.current = {
        ...partyRef.current,
        partyId: [],
      };
    }
    setSelectedOption(selected);
    partyRef.current = {
      ...partyRef.current,
      partyId: selected.map((item) => item?.id),
    };
  };

  return (
    <Fragment>
      <Padding>
        <div>
          <StyledSelectFormControl
            variant="outlined"
            displayEmpty
            style={{ width: 580 }}
          >
            <MySelect
              options={sortedOptions}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={handleChange}
              allowSelectAll={true}
              value={selectedOption}
            />
          </StyledSelectFormControl>
        </div>
        <CustomTable
          hidePagination={state?.lastPage === 1}
          pageSize={10}
          pluralTitle={"Cases"}
          singularTitle={"Cases"}
          placeholderText={"Search"}
          noToolbar={true}
          {...{
            columns,
            filters,
            selectedFilter,
            setSelectedFilter,
            page,
            setPage,
            MTRef,
            loading,
          }}
          data={state}
          state={state}
        />
        <Footer>
          <div className="flex mt20">
            <div
              style={{
                display:
                  selectedFilter === "Drafts" && selectedIds?.some((el) => el)
                    ? "flex"
                    : "none",
                flex: 1,
                justifyContent: "flex-end",
              }}
            >
              <DangerBtn
                style={{
                  width: "216px",
                  marginLeft: "26px",
                }}
                onClick={() => deleteWaring(true)}
              >
                {"Delete"}
              </DangerBtn>
            </div>
          </div>
        </Footer>
        <AlertDialog isOpen={open} {...{ ...dialogData }} />
      </Padding>
    </Fragment>
  );
};

export default CaseTable;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 43px;
`;

const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
`;

const Bold = styled.span`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const Datecolumn = styled.div`
  .date {
    cursor: pointer;
  }
  .delete {
    margin-top: 2px;
    cursor: pointer;
    color: ${COLORS.LOGOUT_RED};
    font-size: 12px;
  }
`;

export const Footer = styled.footer`
  & .highlighted {
    color: red;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
`;

export const DangerBtn = styled(PrimaryCTAButton)`
  background-color: #ff3c3c;
  &:focus,
  &:hover {
    background-color: #ff4d4d;
    border: 1px solid #ff3c3c;
  }
  border: 1px solid #ff3c3c;
`;
