import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import {
  PrimaryOutlinedCTAButton,
  PrimaryCTAButton,
} from "../../common/Buttons";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import {
  TimeBuffer,
  SettingsDuration,
  TranscriptBuffer,
} from "../../../helpers/constants";
import {
  StyledSelectFormControl,
  StyledDropdownIcon,
} from "../../common/CustomSelect/styles";
import { StyledMenuItem } from "../../common/FormInputs";
import { Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  CheckBoxLabel,
  CheckboxContainer,
  CustomCheckbox,
} from "../../common/FormInputs";

export default function TranscriptionSettings({
  modal,
  setModal,
  id,
  meetingDetail,
  setMeetingDetail,
  setRefreshStatus,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [buffer, setBuffer] = useState();
  const [duration, setDuration] = useState();
  const [transcriptBuffer, setTranscriptBuffer] = useState();
  const [checked, setChecked] = useState();

  useEffect(() => {
    if (modal) {
      setBuffer(meetingDetail?.bufferTime);
      setDuration(meetingDetail?.timeInterval);
      setTranscriptBuffer(meetingDetail?.stopTranscriptionDelay);
      setChecked(
        meetingDetail?.isLocalTranscriptionServer === 1 ? true : false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  async function getCaseDetails() {
    try {
      const meetingurl = await CaseService.GetMeetingControl();
      if (modal) {
        setMeetingDetail(meetingurl);
        setBuffer(meetingurl?.bufferTime);
        setDuration(meetingurl?.timeInterval);
        setChecked(meetingurl?.isLocalTranscriptionServer === 1 ? true : false);
        setTranscriptBuffer(meetingurl?.stopTranscriptionDelay);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  }

  async function onFormSubmit(duration, buffer) {
    try {
      setLoader({
        state: true,
        message: `Update settings...`,
      });
      const paylod = {
        timeInterval: duration,
        bufferTime: buffer,
        stopTranscriptionDelay: transcriptBuffer,
        isLocalTranscriptionServer: checked,
      };
      if (id) {
        const response = await CaseService.caseTranscriptionSettings(
          id,
          paylod
        );
        if (response) {
          enqueueSnackbar(response?.message, {
            variant: "success",
          });
        }
        setRefreshStatus(true);
      } else {
        const response = await CaseService.transcriptionSettings(paylod);
        if (response) {
          enqueueSnackbar(response?.message, {
            variant: "success",
          });
        }
        getCaseDetails();
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal(false);
    }
  }

  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: COLORS.INPUT_GRAY,
    },
  }));

  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

  const servers = [
    {
      label: "Local Transcription Server - T2",
      value: 1,
    },
    {
      label: "Local Transcription Server - G4",
      value: 2,
    },
    {
      label: "DG",
      value: 0,
    },
  ];

  return (
    <Modal
      open={modal}
      onClose={() => setModal(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{"Transcription Settings"}</Heading>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../../src/assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <NoteContainer>
          <Label>
            {"Initial Time Buffer"}
            <span className="required-star"> *</span>
          </Label>
          <StyledSelectFormControl variant="outlined">
            <Select
              value={buffer}
              name={buffer}
              displayEmpty
              onChange={(e) => setBuffer(e.target.value)}
              renderValue={
                buffer !== ""
                  ? undefined
                  : () => <Placeholder>{"Select Buffer time"}</Placeholder>
              }
              IconComponent={() => (
                <StyledDropdownIcon
                  alt="select"
                  src={require("../../../assets/images/dropdownIcon.svg")}
                />
              )}
            >
              {TimeBuffer.map((item, idx) => (
                <StyledMenuItem
                  key={idx}
                  value={item.value}
                  disabled={item.disabled}
                >
                  {item.label}
                </StyledMenuItem>
              ))}
            </Select>
          </StyledSelectFormControl>
          <Label>
            {"Select Duration of each Audio chunk"}
            <span className="required-star"> *</span>
          </Label>
          <StyledSelectFormControl variant="outlined">
            <Select
              value={duration}
              name={duration}
              displayEmpty
              onChange={(e) => setDuration(e.target.value)}
              renderValue={
                duration !== ""
                  ? undefined
                  : () => <Placeholder>{"Select Duration"}</Placeholder>
              }
              IconComponent={() => (
                <StyledDropdownIcon
                  alt="select"
                  src={require("../../../assets/images/dropdownIcon.svg")}
                />
              )}
            >
              {SettingsDuration.map((item, idx) => (
                <StyledMenuItem
                  key={idx}
                  value={item.value}
                  disabled={item.disabled}
                >
                  {item.label}
                </StyledMenuItem>
              ))}
            </Select>
          </StyledSelectFormControl>
          <Label>
            {"Stop transcription buffer time"}
            <span className="required-star"> *</span>
          </Label>
          <StyledSelectFormControl variant="outlined">
            <Select
              value={transcriptBuffer}
              name={transcriptBuffer}
              displayEmpty
              onChange={(e) => setTranscriptBuffer(e.target.value)}
              renderValue={
                transcriptBuffer !== ""
                  ? undefined
                  : () => (
                    <Placeholder>
                      {"Select transcription Buffer time"}
                    </Placeholder>
                  )
              }
              IconComponent={() => (
                <StyledDropdownIcon
                  alt="select"
                  src={require("../../../assets/images/dropdownIcon.svg")}
                />
              )}
            >
              {TranscriptBuffer.map((item, idx) => (
                <StyledMenuItem
                  key={idx}
                  value={item.value}
                  disabled={item.disabled}
                >
                  {item.label}
                </StyledMenuItem>
              ))}
            </Select>
          </StyledSelectFormControl>
          {servers.map((item) => (
            <CheckboxContainer style={{ marginTop: 12 }}>
              <CustomCheckbox
                name="checked"
                value={item.value}
                checked={item.value === checked}
                onChange={() => setChecked(item.value)}
              />
              <CheckBoxLabel>{item?.label}</CheckBoxLabel>
            </CheckboxContainer>
          ))}
        </NoteContainer>
        <ButtonWrapper
          style={{
            margin: "auto",
            width: "418px",
            display: "flex",
            justifyContent: "center",
            marginBottom: "52px",
          }}
        >
          <PrimaryOutlinedCTAButton
            style={{
              height: "48px",
              width: "162px",
              marginRight: 20,
            }}
            onClick={() => setModal(false)}
          >
            Cancel
          </PrimaryOutlinedCTAButton>
          <PrimaryCTAButton
            type={"submit"}
            disabled={!buffer || !duration}
            onClick={() => onFormSubmit(duration, buffer)}
            style={{ height: "48px", width: "162px" }}
          >
            Submit
          </PrimaryCTAButton>
        </ButtonWrapper>
      </DrawerContainer>
    </Modal>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 2.7;
  color: ${COLORS.COLOR_DARK};
`;

const DrawerContainer = styled.div`
  max-width: 446px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const NoteContainer = styled.p`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  overflow: auto;
  & .MuiFormControlLabel-root {
    font-family: openSans-ExtraBold, san-serif;
    & .MuiTypography-body1 {
      font-family: openSans-ExtraBold, san-serif;
    }
  }
  & .sub-heading {
    font-family: ${theme.fonts.primaryFontBold};
    font-size: 16px;
    color: ${COLORS.PRIMARY_BLACK};
  }
  & .history-row {
    display: flex;
    border-bottom: solid 0.5px #acb1c2;
    & .MuiCheckbox-colorPrimary.Mui-disabled {
      cursor: not-allowed !important;
    }
  }
  & .content {
    flex: 1;
    padding: 18px 0px;
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    font-weight: 600;
    color: ${COLORS.PRIMARY_BLACK};
  }
`;
