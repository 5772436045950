import API from "../api/manager";
import config from "../api/config";

const negotiations = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.case + `/${id}/negotiations`,
  });
};

const mediations = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.case.comments}/${id}${query}`,
  });
};

const getCase = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.index + `/${id}`,
  });
};

const getcaseDocuments = (id, query) => {
  return API({
    method: "GET",
    url:
      config.urls.case.getCaseDocuments +
      `/case/${id}/dealAgreement` +
      `${query}`,
  });
};

const caseAddDocument = (data, id) => {
  return API({
    method: "POST",
    url: config.urls.case.uploadCase + `/${id}/dealAgreement`,
    data,
  });
};

const assignCaseManager = (data, managerId) => {
  return API({
    method: "PUT",
    url: config.urls.case.assignCase + `/${data?.id}/${managerId}`,
    data: {},
  });
};

const sendComment = (id, data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.comments}/${id}`,
    data,
  });
};

const deleteDraftCase = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.draft + `/${id}`,
    data: {},
  });
};

const deleteSelectedCases = (data) => {
  return API({
    method: "DELETE",
    url: config.urls.case.selectDraft,
    data,
  });
};

const addAllCases = (query) => {
  return API({
    method: "POST",
    url: config.urls.case.select + query,
    data: {},
  });
};

const removeAllCases = (query) => {
  return API({
    method: "DELETE",
    url: config.urls.case.select + query,
    data: {},
  });
};

const selectCaseItem = (id) => {
  return API({
    method: "POST",
    url: config.urls.case.select + `/${id}`,
    data: {},
  });
};

const removeCaseItem = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.select + `/${id}`,
    data: {},
  });
};

const getPartyListByCaseId = (caseId, param) => {
  return API({
    method: "GET",
    url: config.urls.case.caseIndex + `/${caseId}/parties${param}`,
  });
};

const getMeeting = (id) => {
  return API({
    method: "GET",
    url: config.urls.meeting.meetings + `/${id}`,
  });
};

const RecordingControl = (id, recordingStatus) => {
  return API({
    method: "PUT",
    url: config.urls.meeting.recordControl + `/${id}/${recordingStatus}`,
  });
};

const GetMeetingcases = (id) => {
  return API({
    method: "GET",
    url: config.urls.meeting.mettingcase + `/${id}`,
  });
};

const getRoom = (query) => {
  return API({
    method: "GET",
    url: config.urls.meeting.meetingDet + query,
  });
};

const DisableEnableMeeting = (id, meetingStatus) => {
  return API({
    method: "PUT",
    url: config.urls.meeting.meetingaccessadmin + `/${id}/${meetingStatus}`,
  });
};

const updateCase = (caseId, data) => {
  return API({
    method: "PUT",
    url: config.urls.case.index + `/${caseId}`,
    data,
  });
};

const getOrders = (query) => {
  return API({ method: "GET", url: config.urls.cart.orders + query });
};

const getRefundHistory = (query) => {
  return API({ method: "GET", url: config.urls.payment.payments + query });
};

const downloadCaseAgreement = (id) => {
  return API({
    method: "GET",
    url: config.urls.case.uploadCase + `/${id}/negotiationAgreement`,
  });
};

const publishMediator = (data) => {
  return API({
    method: "PUT",
    url: config.urls.case.publishMediator,
    data,
  });
};

const addTranscript = (data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.transcripts}`,
    data,
  });
};

const updateTranscript = (data, id) => {
  return API({
    method: "PUT",
    url: config.urls.case.transcripts + `/${id}`,
    data,
  });
};

const deleteTranscriptDoc = (id) => {
  return API({
    method: "DELETE",
    url: config.urls.case.transcripts + `/${id}`,
    data: {},
  });
};

const getMeetingVideo = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.meetingRecordings}/${id}${query}`,
  });
};

const getSplitAudio = (query, id) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.getAudioFile}/${id}${query}`,
  });
};

const getAudioDetails = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.getMeetingAudioDetails}/${id}${query}`,
  });
};

const updateClaimDescription = (id, data) => {
  return API({
    method: "PUT",
    url: config.urls.case.updateClaimDescription + `/${id}`,
    data,
  });
};

const updateCaseReopen = (id, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.reOpenCase}/${id}`,
    data,
  });
};

const joinMeeting = (adminId) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.genericMeetingJoin}/${adminId}`,
  });
};

const getRecordings = (query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.genericMeetingRecordings}/${query}`,
  });
};

const getMeetingLink = () => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.genericMeetingUrl}`,
  });
};

const getGenericMeeting = (data, code) => {
  return API(
    {
      method: "POST",
      url: `${config.urls.meeting.genericMeeting}/${code}`,
      data,
    },
    false,
  );
};

const getTranscriptionData = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getTranscriptionData}/${id}`,
  });
};

const transcriptionControl = (id, status) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.transcriptionControl}/${id}/${status}`,
  });
};

const GetMeetingControl = () => {
  return API({
    method: "GET",
    url: config.urls.meeting.mettingcase,
  });
};

const getLiveTranscription = (transcriptId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getLiveTranscription}/${transcriptId}`,
  });
};

const enableLiveTranscription = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.enableLiveTranscription}/${id}`,
  });
};

const getLiveTranscriptionData = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getLiveTranscriptionData}/${id}`,
  });
};

const enableLiveTranscriptionCommon = () => {
  return API({
    method: "GET",
    url: `${config.urls.case.enableLiveTranscription}`,
  });
};

const getLiveTranscriptionDataGenric = () => {
  return API({
    method: "GET",
    url: `${config.urls.case.getLiveTranscriptionData}`,
  });
};

const GetMeetingControlTranscription = (id) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.mettingcase}/${id}`,
  });
};

const transcriptionControlCM = (status) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.transcriptionControlCM}/${status}`,
  });
};

const updateLiveTranscriptionData = (data, id) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.updateLiveTranscriptionData}/${id}`,
    data,
  });
};

const updateTranscriptionData = (data, id) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.updateTranscriptionData}/${id}`,
    data,
  });
};

const stopTranscriptionGenric = () => {
  return API({
    method: "PUT",
    url: `${config.urls.meeting.stopTranscription}`,
  });
};

const caseStopTranscription = (caseId) => {
  return API({
    method: "PUT",
    url: `${config.urls.meeting.stopTranscription}/${caseId}`,
  });
};

const transcriptionSettings = (data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.transcriptionControlCM}`,
    data,
  });
};

const caseTranscriptionSettings = (id, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.transcriptionControl}/${id}`,
    data,
  });
};

const startTranscription = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.startTranscription}/${caseId}`,
  });
};

const genricstartTranscription = () => {
  return API({
    method: "GET",
    url: `${config.urls.case.startTranscription}`,
  });
};

const getLiveTranscriptDet = (id, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.liveTranscriptDet}/${id}${query}`,
  });
};

const getTranscriptionStatus = (meetingId) => {
  return API({
    method: "GET",
    url: `${config.urls.transcription.getTranscriptionStatus}/${meetingId}`,
  });
};

const moveTranscriptToDoc = (meetingId) => {
  return API({
    method: "DELETE",
    url: `${config.urls.transcription.moveTranscriptToDoc}/${meetingId}`,
  });
};

const downloadTranscripts = (data) => {
  return API({
    method: "POST",
    url: `/downloadTranscripts`,
    data,
  });
};

const getMeetingAudioTranscript = (meetingAudioId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getMeetingAudioTranscript}/${meetingAudioId}`,
  });
};

const updateAudioTranscriptData = (data, meetingAudioId) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.updateAudioTranscriptData}/${meetingAudioId}`,
    data,
  });
};

const CaseManagerIdBulkCase = (data, caseManagerId) => {
  return API({
    method: "PUT",
    url: `${config.urls.case.assignCase}/${caseManagerId}`,
    data,
  });
};

const getCaseMeetingDetails = (meetingId, query) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.getCaseMeetingDetails}/${meetingId}${query}`,
  });
};

const updateMeetingDetails = (data) => {
  return API({
    method: "POST",
    url: config.urls.meeting.updateMeetingDetails,
    data,
  });
};

const postTranscriptionData = (data) => {
  return API({
    method: "POST",
    url: config.urls.transcription.postTranscriptionData,
    data,
  });
};

const getTranscriptionDataCrossAndRunning = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getTranscriptionData}/${caseId}`,
  });
};

const updateXExamTranscript = (data, meetingId) => {
  return API({
    method: "PUT",
    url: `${config.urls.transcription.updateXExamTranscript}/${meetingId}`,
    data,
  });
};

const moveTranscriptToRecordings = (meetingId) => {
  return API({
    method: "DELETE",
    url: `${config.urls.transcription.moveTranscriptToRecordings}/${meetingId}`,
    data: {},
  });
};

const updateXExamTranscriptDoc = (data, transcriptId) => {
  return API({
    method: "PUT",
    url: `${config.urls.transcription.updateXExamTranscriptDoc}/${transcriptId}`,
    data,
  });
};

const getCaseDetailsForInvoice = (caseId) => {
  return API({
    method: "GET",
    url: `${config.urls.case.getCaseDetailsForInvoice}/${caseId}`,
  });
};

const generateInvoice = (data) => {
  return API({
    method: "POST",
    url: `${config.urls.case.generateInvoice}`,
    data,
  });
};

const dailyCauseList = () => {
  return API({
    method: "GET",
    url: `${config.urls.case.dailyCauseList}`,
  });
};

const generateRTMPLink = (meetingId) => {
  return API({
    method: "GET",
    url: `${config.urls.meeting.generateRTMPLink}/${meetingId}`,
  });
};

const getAllCases = (query) => {
  return API({
    method: "GET",
    url: config.urls.case.getAllCases + query,
  });
};

export default {
  negotiations,
  getCase,
  getRoom,
  getOrders,
  getRefundHistory,
  getcaseDocuments,
  caseAddDocument,
  assignCaseManager,
  mediations,
  sendComment,
  deleteDraftCase,
  getMeeting,
  RecordingControl,
  GetMeetingcases,
  DisableEnableMeeting,
  deleteSelectedCases,
  addAllCases,
  removeAllCases,
  selectCaseItem,
  removeCaseItem,
  getPartyListByCaseId,
  updateCase,
  downloadCaseAgreement,
  publishMediator,
  addTranscript,
  updateTranscript,
  deleteTranscriptDoc,
  getMeetingVideo,
  getSplitAudio,
  getAudioDetails,
  updateClaimDescription,
  updateCaseReopen,
  joinMeeting,
  getRecordings,
  getMeetingLink,
  getGenericMeeting,
  getTranscriptionData,
  transcriptionControl,
  GetMeetingControl,
  getLiveTranscription,
  enableLiveTranscription,
  getLiveTranscriptionData,
  enableLiveTranscriptionCommon,
  getLiveTranscriptionDataGenric,
  GetMeetingControlTranscription,
  transcriptionControlCM,
  updateLiveTranscriptionData,
  updateTranscriptionData,
  stopTranscriptionGenric,
  caseStopTranscription,
  transcriptionSettings,
  caseTranscriptionSettings,
  startTranscription,
  genricstartTranscription,
  getLiveTranscriptDet,
  getTranscriptionStatus,
  moveTranscriptToDoc,
  downloadTranscripts,
  getMeetingAudioTranscript,
  updateAudioTranscriptData,
  CaseManagerIdBulkCase,
  getCaseMeetingDetails,
  updateMeetingDetails,
  postTranscriptionData,
  getTranscriptionDataCrossAndRunning,
  updateXExamTranscript,
  moveTranscriptToRecordings,
  updateXExamTranscriptDoc,
  getCaseDetailsForInvoice,
  generateInvoice,
  dailyCauseList,
  generateRTMPLink,
  getAllCases,
};
