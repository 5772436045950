import { useLocation } from "@reach/router";
import React, { useEffect, useState } from "react";
import useCartCount from "../../../hooks/useCartCount";
import CasesTable from "../../common/CasesTable/CaseTable";
import _ from "lodash";
import ActionBar from "../../common/ActionBar";
import labels from "../../../helpers/labels.json";
import AssignCaseManagerBulkCase from "../../pages/AssignCaseManagerBulkCase/index";

const CaseListing = () => {
  const location = useLocation();
  const { stats } = useCartCount();
  const [caseType, setCaseType] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const [modal, setModal] = useState({ state: false });

  useEffect(() => {
    if (searchParams.get("caseType") === null) {
      setCaseType("negotiation");
    } else {
      setCaseType(searchParams.get("caseType"));
    }
  }, [searchParams, stats]);

  const actions = [
    {
      text: "Assign Case Manager",
      props: {
        onClick: () => setModal({ state: true }),
      },
    },
  ];

  return (
    <div>
      <ActionBar
        actions={actions}
        breadcrumbs={[_.startCase(labels.cases), _.startCase(caseType)]}
      />
      {caseType && (
        <CasesTable
          caseType={caseType}
          status={searchParams.get("status")}
          currentCaseType={caseType}
        />
      )}
      <AssignCaseManagerBulkCase {...{ modal, setModal }} />
    </div>
  );
};

export default CaseListing;
