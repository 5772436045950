import React, { useState } from "react";
import { Heading } from "../../../styles/component/style";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { HyperLink } from "../../../styles/component/style";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
} from "@material-ui/core";
import moment from "moment";
import { numberFormat, parseTimeStamp } from "../../../helpers/functions";
import CaseDescriptionModal from "../CaseDescriptionModal";
import AgentListModal from "../AgentListModal/AgentListDrawer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AccordionHeading } from "../../internal/DrawerTable";
import ClaimItemsListModal from "../../common/ClaimItemsListModal/index";
import CounterClaimModal from "../ClaimItemsListModal/CounterClaim";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    border: `solid 0.5px #e1e3ee`,
  },
  content: {
    margin: `0 !important`,
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: `0 !important`,
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: `0 !important`,
  },
}));

const Index = ({ caseDetails, setState, MTRef, setFields, partyList = [] }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const [modal, setModal] = useState();
  // const [type, setType] = useState("");
  const [opendrawer, setOpenDrawer] = useState(false);
  const [opendrawerClaim, setOpenDrawerClaim] = useState(false);
  const [claimModal, setClaimModal] = useState(false);

  return (
    <>
      <Accordion className={classes.root} expanded={expanded}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{ content: classes.content }}
        >
          <Header>
            <Heading style={{ marginBottom: "unset" }}>
              {caseDetails.title}
            </Heading>
            <Actions>
              {caseDetails?.resolutionKind === "arbitration" && (
                <HyperLink onClick={() => setFields(true)}>
                  Case Details
                </HyperLink>
              )}
              {partyList.length ? (
                <HyperLink
                  style={{ display: "table" }}
                  onClick={() => setOpenDrawer((a) => ({ ...a, state: true }))}
                >
                  Agent Details
                </HyperLink>
              ) : null}
              <HyperLink onClick={() => setState(true)}>
                Case Documents
              </HyperLink>
              <DropDown>
                <Icon
                  onClick={() => setExpanded((e) => !e)}
                  src={require("../../../assets/images/dropdownIcon.svg")}
                  alt="dropdown"
                  expanded={expanded}
                />
              </DropDown>
            </Actions>
          </Header>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <TableWrapper>
            <tbody>
              <Tr>
                <Th>Case ID</Th>
                {caseDetails?.resolutionKind === "arbitration" && (
                  <Th>Contract Number</Th>
                )}
                <Th>Claimant</Th>
                <Th>Respondent</Th>
                <Th>Monetary Claim Value</Th>
                <Th>Filed on</Th>
                <Th>Last Updated</Th>
              </Tr>
              <Tr>
                <Td>{caseDetails.id}</Td>
                {caseDetails?.resolutionKind === "arbitration" && (
                  <Td>
                    {caseDetails.loanAccountNo
                      ? caseDetails?.loanAccountNo
                      : "-"}
                  </Td>
                )}
                <Td>{caseDetails?.claimantParty?.name}</Td>
                <Td>
                  {caseDetails?.respondentParty?.name ||
                  caseDetails.respondentName ? (
                    <>
                      {caseDetails?.respondentParty?.name
                        ? caseDetails?.respondentParty?.name
                        : caseDetails.respondentName}
                    </>
                  ) : (
                    <>
                      {caseDetails?.respondentParties?.length
                        ? caseDetails?.respondentParties[0]?.name
                        : ""}
                    </>
                  )}
                  {partyList?.filter((el) => el.partyRole === "respondent")
                    ?.length -
                    1 >
                  0 ? (
                    <HyperLink
                      style={{ marginLeft: 8, display: "inline" }}
                      onClick={() =>
                        setOpenDrawer((a) => ({ ...a, state: true }))
                      }
                    >
                      +{" "}
                      {partyList?.filter((el) => el.partyRole === "respondent")
                        ?.length - 1}
                    </HyperLink>
                  ) : null}
                </Td>
                <Td>
                  {caseDetails.totalClaimValue
                    ? numberFormat(
                        parseFloat(caseDetails.totalClaimValue).toFixed(2),
                        caseDetails.currencyUnit,
                      )
                    : "Non Monetary"}
                  {caseDetails?.resolutionKind === "mediation" &&
                  caseDetails?.caseItems?.length ? (
                    <HyperLink
                      style={{ marginLeft: 8, display: "inline" }}
                      onClick={() =>
                        setOpenDrawerClaim((a) => ({ ...a, state: true }))
                      }
                    >
                      +{" "}
                    </HyperLink>
                  ) : (
                    ""
                  )}
                  {caseDetails?.resolutionKind === "arbitration" &&
                  caseDetails?.counterClaimValue !== 0 ? (
                    <HyperLink
                      style={{ marginLeft: 8, display: "inline" }}
                      onClick={() => setClaimModal(true)}
                    >
                      +{" "}
                    </HyperLink>
                  ) : null}
                </Td>
                <Td>
                  {caseDetails.created_at &&
                    moment(parseTimeStamp(caseDetails.created_at)).format(
                      "DD/MM/YYYY",
                    )}
                </Td>
                <Td>
                  {caseDetails.updated_at &&
                    moment(parseTimeStamp(caseDetails.updated_at)).format(
                      "DD/MM/YYYY",
                    )}
                </Td>
              </Tr>
            </tbody>
          </TableWrapper>
          <TableWrapper style={{ marginTop: 25, marginBottom: 15 }}>
            <tbody>
              <Tr>
                <Td>
                  <Accordion className={classes.root}>
                    <AccordionSummary
                      style={{ marginRight: "0px" }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <AccordionHeading
                        style={{ display: "flex", paddingLeft: "0px" }}
                      >
                        Case Description
                        {caseDetails?.caseTopic?.length > 0 && (
                          <span
                            style={{
                              marginLeft: "12px",
                              color: "rgba(246,180,54, 18)",
                            }}
                          >
                            - Keywords - ({caseDetails.caseTopic.join(", ")})
                          </span>
                        )}
                      </AccordionHeading>
                    </AccordionSummary>
                    <CommentContent
                      style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        paddingBottom: "30px",
                        paddingTop: "auto",
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                    >
                      {caseDetails?.description}
                    </CommentContent>
                  </Accordion>
                </Td>
              </Tr>
            </tbody>
          </TableWrapper>

          <Row>
            <Column>
              {caseDetails?.resolutionKind === "negotiation" ? (
                <div>
                  <span className="label">Total Rounds:</span>{" "}
                  {caseDetails?.negotiationRoundLimit}
                </div>
              ) : null}
            </Column>
          </Row>
        </AccordionDetails>
      </Accordion>
      <AgentListModal
        {...{ opendrawer, setOpenDrawer, MTRef, partyList, caseDetails }}
      />
      <ClaimItemsListModal
        {...{
          opendrawerClaim,
          setOpenDrawerClaim,
          MTRef,
          displayAuthUrl: false,
        }}
        caseDetails={caseDetails}
      />
      <CounterClaimModal
        {...{
          claimModal,
          setClaimModal,
          MTRef,
          caseDetails,
        }}
      />
      <CaseDescriptionModal
        {...{ modal, setModal }}
        description={caseDetails?.description}
      />
    </>
  );
};

export default Index;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  width: 100%;
  cursor: default;
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  & div {
    margin-left: 35px;
  }
`;
const DropDown = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 17px 0 25px;
  border-radius: 50%;
  background-color: ${COLORS.TABLE_GREY_CELL};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const TableWrapper = styled.table`
  margin: 6px 0 7px 0;
  padding: 0 21px 0 21px;
  width: 100%;
`;
const Tr = styled.tr``;
const Th = styled.th`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
  text-align: left;
  width: 6.66%;
  //width: 0.50%;
`;
const Td = styled.td`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  color: #293461;
`;

const Column = styled.div`
  padding: 20px 22px;
  max-width: 50%;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  & div {
    margin-bottom: 10px;
    font-family: ${theme.fonts.primaryFontBold};
    font-size: 14px;
    color: #293461;
  }
  & .label {
    font-size: 10px;
    font-weight: 600;
    color: ${COLORS.INPUT_LABEL};
  }
`;

const Row = styled.div`
  display: flex;
`;

const Icon = styled.img`
  width: 11px;
  height: 6px;
  object-fit: "contain";
  transform: rotate(${({ expanded }) => (expanded ? 180 : 0)}deg);
  transition: 5sec;
`;

const CommentContent = styled.div`
  font-size: 14px;
  word-break: break-word;
  text-align: justify;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontSemiBold};
  // margin-top: 10px;
  & strong,
  & bold {
    font-family: ${theme.fonts.primaryFontExtraBold};
  }
  & .ql-align-center {
    text-align: center;
  }
  & .ql-align-right {
    text-align: right;
  }
  // & blockquote {
  //   border-left: 4px solid #ccc;
  //   margin-bottom: 5px;
  //   margin-top: 5px;
  //   padding-bottom: 5px;
  //   padding-top: 5px;
  //   padding-left: 16px;
  //   background-color: #f9f9f9;
  // }
`;
