import React from "react";
import MaterialTable, { MTableCell } from "material-table";
import { Paper } from "@material-ui/core";
import styled from "styled-components";
import COLORS from "../../assets/Colors";
import theme from "../../assets/theme";
import tableIcons from "./MaterialTableIcons";
import CustomPagination from "./MaterialPagination";
import TableToolbar from "./TableToolbar";

export default function CustomTable({
  hideSearch = false,
  hidePagination = false,
  hideFilterBar = false,
  filters,
  selectedFilter,
  setSelectedFilter,
  placeholderText,
  pageSize = 5,
  MTRef = {},
  state = {},
  noToolbar,
  pluralTitle = "Records",
  singularTitle = "record",
  customFilter,
  filterCount = 0,
  selection = false,
  onFilterClick = () => {},
  onSelectionChange = () => {},
  customMessage,
  top,
  left,
  page,
  setPage,
  loading,
  ...props
}) {
  return (
    <div className="custom-material-tables">
      <DisplayMaterialTable>
        <MaterialTable
          title=""
          isLoading={loading}
          icons={tableIcons}
          tableRef={MTRef}
          localization={{
            body: {
              emptyDataSourceMessage: (
                <TableEmpty>
                  <H2>No {pluralTitle} found</H2>
                </TableEmpty>
              ),
            },
          }}
          detailPanel={props.detailPanel}
          options={{
            headerStyle: {
              whiteSpace: "nowrap",
              color: "#ffffff",
            },
            sorting: true,
            toolbar: noToolbar,
            pageSize,
            detailPanelType: "multiple",
            detailPanelColumnAlignment: "right",
            pageSizeOptions: [pageSize],
            emptyRowsWhenPaging: state?.data?.length === 0,
            // maxBodyHeight: 460,
            showEmptyDataSourceMessage: loading ? false : true,
            selection,
            selectionProps: {
              checkedIcon: (
                <img
                  alt="checked"
                  src={require("../../assets/images/checkBoxSelected.svg")}
                />
              ),
              icon: (
                <img
                  alt="checked"
                  src={require("../../assets/images/checkbox.svg")}
                />
              ),
              disableRipple: true,
            },
            showSelectAllCheckbox: selection,
          }}
          components={{
            Container: (props) => (
              <Paper
                {...props}
                style={{ backgroundColor: "transparent" }}
                elevation={0}
              />
            ),
            Cell: (props) => {
              return <StyledCell {...props} />;
            },
            Toolbar: (props) => {
              return (
                <TableToolbar
                  {...{
                    props,
                    filters,
                    MTRef,
                    selectedFilter,
                    setSelectedFilter,
                    placeholderText,
                    hideFilterBar,
                    hideSearch,
                    customFilter,
                    filterCount,
                    onFilterClick,
                  }}
                />
              );
            },
            Pagination: (props) => {
              return (
                <CustomPagination
                  count={Math.ceil(props.count / pageSize)}
                  onChangePage={props.onChangePage}
                  onChangeRowsPerPage={props.onChangeRowsPerPage}
                  page={props.page}
                  colSpan={props.count}
                  {...{ props, page, setPage }}
                />
              );
            },
          }}
          onSelectionChange={onSelectionChange}
          {...props}
        />
      </DisplayMaterialTable>
    </div>
  );
}

const DisplayMaterialTable = styled.div`
  display: none;
  @media (min-width: 780px) {
    display: block;
  }
`;

const StyledCell = styled(MTableCell)`
  border-bottom: 0;
  font-family: ${theme.fonts.primaryFontRegular} !important;
  color: ${COLORS.COLOR_DARK} !important;
  @media (max-width: 1440px) {
    font-size: 12px;
  }
`;

const TableEmpty = styled.span`
  color: ${COLORS.COLOR_DARK};
  font-weight: 300;
  letter-spacing: 0.1px;
  font-size: 11px;
  font-family: ${theme.fonts.primaryFontBold};
  text-align: center;
`;

const H2 = styled.h2`
  padding-top: 185px;
`;
