import { useContext } from "react";
import { RefContext } from "../providers/RefProvider";

export default function useRefContext() {
  const context = useContext(RefContext);
  if (context === undefined) {
    throw new Error("useRefContext must be used within a RefProvider");
  }
  return context;
}
