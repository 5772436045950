import React, { useState } from "react";
import { navigate } from "@reach/router";
import labels from "../../../../helpers/labels.json";
import { PrimaryCTAButton } from "../../../common/Buttons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import ExpansionPanel from "../../../common/Accordion";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const TermsAndCondition = ({ systemConfig, StyleCard }) => {
  const useStyles = makeStyles((themes) => ({
    root1: {
      width: "100%",
      backgroundColor: "white",
      boxShadow: "none",
    },
    secondaryHeading: {
      maxWidth: "960px",
      width: "100%",
      fontFamily: theme.fonts.primaryFontRegular,
      fontSize: "14px",
      color: COLORS.COLOR_DARK,
      lineHeight: "1.57",
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <CardWrapper style={StyleCard}>
      <Accordion expanded={open} className={classes.root1}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => setOpen(!open)} />}
        >
          <div className="heading">
            <FlexBetween>
              <CardHeader>{labels.terms_and_conditions}</CardHeader>
              <WrapperButton>
                <PrimaryCTAButton
                  onClick={() =>
                    navigate("/dashboard/configurations/terms-and-condition")
                  }
                  style={{ padding: "unset", height: 35 }}
                >
                  {labels.update}
                </PrimaryCTAButton>
              </WrapperButton>
            </FlexBetween>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%" }}>
            <ExpansionPanel data={systemConfig.tnc} />
          </div>
        </AccordionDetails>
      </Accordion>
    </CardWrapper>
  );
};

export default TermsAndCondition;

export const CardWrapper = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  width: 618px;
  padding: 0 44px 30px 19px;
  border-radius: 11px;
  border: solid 0.5px #e4e5ef;
  .heading {
    width: 100%;
  }
`;

const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
`;

export const CardHeader = styled.h2`
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

const WrapperButton = styled.div`
  width: 146px;
  margin-right: 15px;
`;
