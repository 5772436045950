import React, { useRef, useState } from "react";

export const RefContext = React.createContext();

function RefProvider({ children }) {
  let partyRef = useRef({
    partyId: [],
  });
  const [selectedOption, setSelectedOption] = useState([]);

  const value = React.useMemo(() => {
    return {
      partyRef,
      selectedOption,
      setSelectedOption,
    };
  }, [partyRef, selectedOption, setSelectedOption]);

  return <RefContext.Provider value={value}>{children}</RefContext.Provider>;
}

export default RefProvider;
