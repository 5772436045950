import React, { useEffect, useState, memo } from "react";
import styled from "styled-components";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ActionBar from "../../../common/ActionBar";
import { navigate } from "@reach/router";
import _ from "lodash";
import { PrimaryCTAButton, SecondaryCTAButton } from "../../../common/Buttons";
import { CustomCheckBox, CustomInputField } from "../../../common/FormInputs";
import { Formik } from "formik";
import SystemService from "../../../../services/SystemService";
import useLoader from "../../../../hooks/useLoader";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
  table: {
    backgroundColor: "transparent",
    borderRadius: 7,
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
  tableHead: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    color: COLORS.INPUT_LABEL,
    fontSize: 10,
    paddingBottom: 0,
  },
  row: {
    borderBottom: `1px solid ${COLORS.INPUT_BORDER}`,
  },
  radioGroup: {
    "& .MuiRadio-colorPrimary.Mui-checked": {
      color: COLORS.BTN_GREEN,
    },
  },
  formLabel: {
    fontFamily: theme.fonts.primaryFontRegular,
    color: COLORS.COLOR_DARK,
    marginRight: 5,
    // minWidth: 98,
  },
  radioButton: {
    color: COLORS.BTN_GREEN,
  },
});

const CaseStageState = () => {
  const classes = useStyles();
  const [caseStage, setCaseStage] = useState([]);
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [trigger, setTrigger] = useState(true);
  const initialState = {
    caseStage: "",
    detailedStagesLabel: "",
    detailedStage: "",
    caseLifeCycleDisplayFlag: false,
  };

  useEffect(() => {
    async function getCaseStages() {
      try {
        setLoader({ state: true, message: "Fetching details..." });
        const response = await SystemService.getCaseStages();
        if (response) {
          setCaseStage(response?.caseStages);
        }
      } catch (err) {
        throw err;
      } finally {
        setTrigger(false);
        setLoader({ state: false });
        window.scrollTo(0, 0);
      }
    }
    if (trigger) {
      getCaseStages();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case "configurations":
        navigate("/dashboard/configurations");
        break;
      default:
        break;
    }
  };

  const addCaseStageItems = (values, setFieldValue) => {
    const new_case_stages = [
      ...values?.case_stage_items,
      {
        caseStage: "",
        detailedStagesLabel: "",
        detailedStage: "",
        caseLifeCycleDisplayFlag: "",
        addItem: true,
      },
    ];
    setFieldValue("case_stage_items", new_case_stages);
  };

  const deleteCaseStages = async (index, values, setFieldValue, id) => {
    try {
      setLoader({ state: true, message: "delete item..." });
      const new_case_stages = [...values?.case_stage_items].filter(
        (ci, idx) => idx !== index,
      );
      const response = await SystemService.deleteCaseStages(id);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      setFieldValue("case_stage_items", new_case_stages);
    } catch (err) {
      enqueueSnackbar(err?.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  const InputField = memo(({ key, name, onChange, onBlur, value }) => {
    return (
      <div>
        <CustomInputField
          className={"input-white"}
          variant="outlined"
          key={key}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
    );
  });

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "update case stages..." });
      let filteredItems = values?.case_stage_items
        .filter((arr) => arr?.addItem)
        .map(({ addItem, ...item }) => item);
      const payload = {
        caseStagesParams: filteredItems,
      };
      const response = await SystemService.caseStages(payload);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  /**
   * @description update case stages
   */

  async function updateCaseStages(values, name, event, setFieldValue) {
    try {
      setLoader({ state: true, message: "update case stages..." });
      let value = event.target.checked;
      const payload = {
        caseStagesParams: {
          caseStage: values?.caseStage,
          detailedStagesLabel: values?.detailedStagesLabel,
          detailedStage: values?.detailedStage,
          caseLifeCycleDisplayFlag: value,
        },
      };
      const response = await SystemService.updateCaseStages(
        values?.id,
        payload,
      );
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
      setFieldValue(name, value);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <OuterContainer>
      <ActionBar
        breadcrumbs={["Configurations", "Case Stages"]}
        {...{ onBreadcrumbClick }}
      />
      <Container>
        <Formik
          initialValues={{
            case_stage_items: caseStage?.length
              ? caseStage?.map((item) => ({
                  ...item,
                  caseStage: item?.caseStage,
                  detailedStagesLabel: item?.detailedStagesLabel, // Comment for always its going to INR
                  detailedStage: item?.detailedStage,
                  caseLifeCycleDisplayFlag: item?.caseLifeCycleDisplayFlag,
                  id: item?.id,
                }))
              : [initialState] || [initialState],
          }}
          onSubmit={onFormSubmit}
          enableReinitialize
          validateOnBlur
          validateOnChange
          validateOnMount
        >
          {({
            values,
            handleChange,
            errors,
            handleSubmit,
            touched,
            setFieldError,
            setFieldValue,
            handleBlur,
            isValid,
            setFieldTouched,
          }) => (
            <FormContainer className="material-table" onSubmit={handleSubmit}>
              <TableContainer>
                <TableWrapper>
                  <BigTable>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              width: 253,
                            }}
                            className={classes.tableHead}
                          >
                            Case Stage
                          </TableCell>
                          <TableCell
                            style={{
                              width: 253,
                            }}
                            className={classes.tableHead}
                          >
                            Detailed Stage Label{" "}
                          </TableCell>
                          <TableCell
                            style={{
                              width: 253,
                            }}
                            className={classes.tableHead}
                          >
                            Detailed Stage{" "}
                          </TableCell>
                          <TableCell
                            style={{
                              width: 190,
                            }}
                            className={classes.tableHead}
                          >
                            Case Life Cycle{" "}
                          </TableCell>
                          <TableCell className={classes.tableHead}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values?.case_stage_items?.map((stage, index) => (
                          <TableRow className={"table-row"} key={index}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ position: "relative" }}
                            >
                              <InputField
                                key={index}
                                className={"input-white"}
                                value={stage.caseStage}
                                name={`case_stage_items[${index}].caseStage`}
                                onChange={handleChange}
                                variant="outlined"
                                onBlur={handleBlur}
                              />
                            </TableCell>
                            <TableCell
                              className="disabledArrows"
                              style={{ position: "relative" }}
                            >
                              <InputField
                                key={index}
                                className={"input-white"}
                                value={stage.detailedStagesLabel}
                                name={`case_stage_items[${index}].detailedStagesLabel`}
                                onChange={handleChange}
                                variant="outlined"
                                onBlur={handleBlur}
                              />
                            </TableCell>
                            <TableCell
                              className="disabledArrows"
                              style={{ position: "relative" }}
                            >
                              <InputField
                                key={index}
                                className={"input-white"}
                                value={stage.detailedStage}
                                name={`case_stage_items[${index}].detailedStage`}
                                onChange={handleChange}
                                variant="outlined"
                                onBlur={handleBlur}
                              />
                            </TableCell>
                            <TableCell
                              className="disabledArrows"
                              style={{ position: "relative" }}
                            >
                              <CheckboxContainer>
                                <CustomCheckBox
                                  checked={stage.caseLifeCycleDisplayFlag}
                                  name={`case_stage_items[${index}].caseLifeCycleDisplayFlag`}
                                  onChange={(event) =>
                                    updateCaseStages(
                                      stage,
                                      `case_stage_items[${index}].caseLifeCycleDisplayFlag`,
                                      event,
                                      setFieldValue,
                                    )
                                  }
                                  variant="outlined"
                                  onBlur={handleBlur}
                                />
                                <CheckBoxLabel>Case Life Cycle</CheckBoxLabel>
                              </CheckboxContainer>
                            </TableCell>
                            <TableCell align="center" style={{ width: 100 }}>
                              {values.case_stage_items.length > 1 && (
                                <DeleteIcon
                                  onClick={() =>
                                    deleteCaseStages(
                                      index,
                                      values,
                                      setFieldValue,
                                      stage.id,
                                    )
                                  }
                                  src={require("../../../../assets/images/removeIcon.svg")}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </BigTable>
                  <ButtonWrapper>
                    <SecondaryCTAButton
                      onClick={() => addCaseStageItems(values, setFieldValue)}
                      style={{ paddingLeft: 0 }}
                    >
                      + Add Stages
                    </SecondaryCTAButton>
                  </ButtonWrapper>
                </TableWrapper>
              </TableContainer>
              <FormControl>
                <Row>
                  <PrimaryCTAButton
                    onClick={handleSubmit}
                    style={{ width: "47%", height: 35 }}
                  >
                    Submit
                  </PrimaryCTAButton>
                </Row>
              </FormControl>
            </FormContainer>
          )}
        </Formik>
      </Container>
    </OuterContainer>
  );
};

export default CaseStageState;

export const OuterContainer = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const Container = styled.div`
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  & .delete {
    display: flex;
    cursor: pointer;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding: 34px 43px;
  }
`;

export const FormContainer = styled.form`
  /* width: 907px; */

  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${theme?.breakpoints?.md_up} {
    width: auto;
  }
`;

export const TableContainer = styled.div`
  margin-top: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 37px 25px 27px;
    border-radius: 5px;
    border: solid 0.5px ${COLORS.INPUT_BORDER};
  }
`;

export const FormControl = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TableWrapper = styled.div`
  @media ${theme?.breakpoints?.md_up} {
    background-color: #f9f9f9;
    border-radius: 7px;
  }
`;

export const ButtonWrapper = styled.div`
  width: fit-content;
  @media ${theme?.breakpoints?.md_up} {
    justify-content: flex-start;
    display: flex;
    width: 200px;
    padding-bottom: 20px;
    padding-top: 7px;
  }
`;

export const DeleteIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
`;

export const BigTable = styled.div`
  display: none;
  @media ${theme?.breakpoints?.md_up} {
    display: block;
  }
`;
