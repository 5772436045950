import React from "react";
import styled from "styled-components";
import FilterBar from "./FilterBar";
import theme from "../../assets/theme";
import COLORS from "../../assets/Colors";

const TableToolbar = ({
  MTRef,
  selectedFilter,
  setSelectedFilter,
  filters,
  placeholderText,
  hideSearch,
  hideFilterBar,
  customFilter,
  filterCount,
  onFilterClick = () => {},
  props,
}) => {
  return (
    <StyledMTableToolbar {...props} hideFilterBar={hideFilterBar}>
      {!hideFilterBar ? (
        <FilterBar
          filters={filters}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      ) : (
        <div />
      )}
      {customFilter && (
        <CustomFilterButton onClick={() => onFilterClick()}>
          <FilterIcon />
          <FilterText>Filters</FilterText>
          <FilterCount>{filterCount}</FilterCount>
        </CustomFilterButton>
      )}
      {!hideSearch && (
        <StyleToolbar>
          <div className="input-icons">
            <div className="search-icons">
              <SearchIcon
                src={require("../../assets/images/searchBar.svg")}
                alt="search_icon"
              />
            </div>
            <StyledInput
              placeholder={placeholderText}
              value={props.searchText}
              onChange={(e) => props.onSearchChanged(e.target.value)}
              backgroundColor={"#ffffff"}
              width={"230px"}
            />
          </div>
        </StyleToolbar>
      )}
    </StyledMTableToolbar>
  );
};

export default TableToolbar;

const StyledMTableToolbar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${(hideFilterBar) =>
    !hideFilterBar ? "flex-end" : "space-between"};
  align-items: center;
  padding-top: 0px;
  padding-bottom: 17px;
`;
const CustomFilterButton = styled.div`
  display: flex;
  align-items: center;
  width: 116px;
  height: 27px;
  border-radius: 4px;
  border: solid 1px ${COLORS.BTN_GREEN};
  background-color: ${COLORS.PRIMARY_WHITE};
  justify-content: space-between;
  cursor: pointer;
  margin-left: 13px;
  user-select: none;
`;

const FilterIcon = styled.img`
  margin-left: 11px;
`;
const FilterText = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 12px;
`;
const FilterCount = styled.span`
  font-size: 16px;
  color: ${COLORS.BTN_GREEN};
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin-right: 18px;
`;

const StyleToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
  margin-top: 12px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 39px;
  border-radius: 10px;
  border: solid 1px #e1e3ee;
  background-color: ${({ backgroundColor }) => backgroundColor};
  outline: none;
  font-size: 14px;
  font-weight: 600;
  color: #acb1c2;
  font-family: ${theme.fonts.primaryFontRegular};
  padding-left: 35px;
  &::placeholder {
    color: #acb1c2;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 220px;
  }
`;

const SearchIcon = styled.img`
  width: 16px;
  height: 16px;
`;
